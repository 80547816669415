@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&family=Ysabeau+SC:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Ysabeau SC", sans-serif;
  background: #1A1A1A;
  color: white;
}
body a {
  color: white;
  text-decoration: none;
}

h1, h2, h3 {
  font-family: "Indie Flower", cursive;
  font-style: italic;
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 2.5em;
  margin: 0;
}

p {
  margin: 0.5em;
}

.Welcome {
  background: url(/client/static/media/fondo-food.21fdb480.jpg);
  background-size: 100%;
  min-height: 100vh;
  height: 100%;
}
.Welcome .Login {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  text-align: center;
  background: rgba(26, 26, 26, 0.8);
  min-width: 350px;
  min-height: 400px;
  border: 5px solid white;
}
.Welcome .Login .Foods {
  display: flex;
  color: white;
  margin-bottom: 2em;
}
.Welcome .Login .Foods__diets {
  width: 50%;
}
.Welcome:after {
  position: absolute;
  content: "";
  background: rgba(26, 26, 26, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.Welcome h1 {
  color: #E9A66B;
  text-align: center;
  margin-bottom: 0.1em;
}

.btn {
  background: transparent;
  padding: 0.5em;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}

.btnSmall {
  padding: 0.8em 2em;
}

.btnLarge {
  padding: 1em 2em;
}

.btnMedium {
  padding: 0.5em 2em;
}

.btnGold {
  background: #E9A66B;
  color: #003319;
  border: 2px solid #E9A66B !important;
}
.btnGold:hover {
  background: #f0c197;
  border: 2px solid #f0c197 !important;
}

.btnOutlineGold {
  background: transparent;
  border: 2px solid #E9A66B !important;
  color: color1;
}
.btnOutlineGold:hover {
  background: #f0c197;
  color: #764112;
  border: 2px solid #f0c197 !important;
}

.btnRed {
  background: #E8313C;
}
.btnRed:hover {
  background: #ed5f67;
}

.btnGreen {
  background: springgreen;
  color: #003319;
}
.btnGreen:hover {
  background: #33ff99;
}

.btnBack {
  font-size: 2em;
  color: springgreen;
}

.btnBlock {
  border-radius: 5px;
  float: right;
  width: 100%;
  padding: 0.8em;
  margin-top: 1em;
  color: white;
  cursor: pointer;
}

.Cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.Card {
  overflow: hidden;
  width: 33.3%;
  min-width: 300px;
}
.Card__img {
  position: relative;
}
.Card__img span {
  position: absolute;
  color: #1A1A1A;
  left: 2px;
  top: 2px;
  font-size: 1.2em;
  background: springgreen;
  border-radius: 10px;
  padding: 0 0.5em 0.2em 0.5em;
  font-weight: bold;
}
.Card__item {
  margin: 2em;
  border: 5px solid white;
  min-height: 330px;
}
.Card__item img {
  width: 100%;
}
.Card__title {
  text-align: center;
  color: #E9A66B;
  font-size: 0.7em;
  min-height: 80px;
}
.Card__description {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Card__description p {
  margin: 0em;
  font-size: 0.9em;
  padding: 0;
}
.Card__description .vegan {
  color: lime;
}
.Card__description .gluten_free {
  color: tomato;
}
.Card__description .paleolithic {
  color: sandybrown;
}
.Card__description .lacto_ovo_vegetarian {
  color: skyblue;
}
.Card__description .primal {
  color: violet;
}
.Card__description .dairy_free {
  color: gold;
}

.vegan {
  color: lime;
}

.gluten_free {
  color: tomato;
}

.paleolithic {
  color: sandybrown;
}

.lacto_ovo_vegetarian {
  color: skyblue;
}

.primal {
  color: violet;
}

.dairy_free {
  color: gold;
}

.Aside {
  width: 20%;
  margin: 2em 0;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: 100%;
}

.Main {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2em;
  position: relative;
}

.Content {
  width: 80%;
}

.Nav {
  background: #1A1A1A;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
.Nav img {
  width: 50px;
}
.Nav .Logo {
  display: flex;
  align-items: center;
}
.Nav .Logo__title {
  font-size: 1.8em;
  color: #E8313C;
}
.Nav .Menu {
  display: flex;
  align-items: center;
}

.searchBar {
  display: flex;
}
.searchBar form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.searchBar select {
  border-radius: 5px;
  background: transparent;
  border: 1px solid white;
  padding: 0.5em;
  color: white;
  width: 100%;
}
.searchBar option {
  color: #1A1A1A;
}
.searchBar button {
  border: 0;
  margin: 1em 0;
  width: 45%;
  cursor: pointer;
}
.searchBar label {
  width: 100%;
}

.Filters__item {
  margin: 1em 0;
}
.Filters__item .labelHealthScore {
  color: springgreen;
}

.inputForm {
  border-radius: 5px;
  background: transparent;
  border: 5px solid white;
  padding: 0.5em;
  color: white;
  width: 100%;
}

.selectForm {
  border-radius: 5px;
  background: transparent;
  border: 5px solid white;
  padding: 0.5em;
  color: white;
  width: 100%;
}
.selectForm option {
  color: #1A1A1A;
}

.form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 500px;
  margin: auto;
  border: 5px solid white;
  padding: 1em 2em;
}
.form form {
  width: 100%;
}
.form h2 {
  width: 100%;
  text-align: center;
  color: #E9A66B;
}
.form .steps {
  display: flex;
  flex-direction: column;
}
.form .steps p {
  margin: 0;
}
.form .steps i {
  font-style: normal;
  color: #E8313C;
  cursor: pointer;
}
.form__input {
  padding: 0.5em 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.form__input input {
  border-radius: 5px;
  border: 1px solid #E9A66B;
  width: 70%;
  padding: 0.3em 0.5em;
}
.form__input textarea {
  border-radius: 5px;
  border: 1px solid #E9A66B;
  width: 70%;
  padding: 0.3em 0.5em;
}
.form__input select {
  border-radius: 5px;
  border: 1px solid #E9A66B;
  width: 70%;
  padding: 0.3em 0.5em;
}
.form__input .btnAdd {
  position: absolute;
  right: 0px;
  font-size: 1.33em;
  padding: 0 0.5em;
}
.form__types {
  display: flex;
  margin-top: 1em;
  flex-wrap: wrap;
}
.form__types span {
  padding: 0 0.4em 0.2em 0.4em;
  border: 1px solid white;
  margin: 0 0.2em;
  border-radius: 15px;
}
.form__types span i {
  font-style: normal;
  cursor: pointer;
}

.danger {
  color: tomato;
  font-size: 0.95em;
  margin: 0;
}

.LoginForm {
  padding: 0 1em;
}

.LoginUser {
  min-height: 320px !important;
}

.Pagination {
  display: flex;
  width: 70%;
  margin: 0.1em auto;
  justify-content: center;
}
.Pagination button {
  background: #E8313C;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.2em 1em 0.2em 1em;
  cursor: pointer;
}
.Pagination .pages {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.Pagination .pages__item {
  list-style: none;
  padding: 0.2em 1em 0.5em 1em;
  margin: 0.1em;
  border: 1px solid #E8313C;
  cursor: pointer;
  border-radius: 5px;
}
.Pagination .pages__item:hover {
  background: #E8313C;
  color: white;
}

.pageActivate {
  background: #E8313C;
  color: white;
}

.Detail {
  width: 80%;
  margin: auto;
  border: 5px solid white;
  padding: 1em;
}
.Detail h3 {
  margin: 0.4em 0;
}
.Detail__article {
  display: flex;
}
.Detail__img {
  max-width: 500px;
}
.Detail__img img {
  width: 100%;
}
.Detail__title {
  color: #E9A66B;
  text-align: center;
  position: relative;
}
.Detail__title h1 {
  margin: 0;
}
.Detail__title a {
  position: absolute;
  left: 0;
  top: 0;
}
.Detail__subtitle b {
  border-bottom: 3px solid springgreen;
}
.Detail__content {
  padding: 1em;
}
.Detail__content .Card__description {
  justify-content: flex-start;
}
.Detail__content .Card__description h4 {
  width: 100%;
}
.Detail__content .Card__description span {
  margin: 0 1em;
}
.Detail p {
  margin: 0;
}
